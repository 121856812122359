import { MouseEventHandler, PropsWithChildren, useEffect, useRef } from 'react'
import { useKey, useWindowSize } from 'react-use'
import { css, SerializedStyles } from '@emotion/react'
import { createPortal } from 'react-dom'
import { AnimatePresence, motion, MotionProps, useWillChange } from 'framer-motion'
import ReactConfetti from 'react-confetti'

import { useTheme } from '@design-system/styles/theme'
import { useIsBrowser } from '@design-system/utils/hooks'
import { run } from '@design-system/utils/helpers'
import { TestProps } from '@design-system/types/propTypes'
import { Button } from '@design-system/components/button/button'
import { EnterIcon, IconName } from '../../enter-icons/enterIcon'

export interface ModalProps extends TestProps {
    isOpen: boolean
    onClose?: () => void
    overlayStyles?: SerializedStyles
    modalStyles?: SerializedStyles
    styleType?: 'overlay' | 'flat'
    modalMotion?: MotionProps
    showConfetti?: boolean
    overlayMotion?: MotionProps
}

export const DefaultModal = ({
    isOpen,
    onClose,
    children,
    overlayStyles,
    modalStyles,
    styleType = 'overlay',
    modalMotion,
    showConfetti,
    overlayMotion,
    dataCy,
    testId,
}: PropsWithChildren<ModalProps>) => {
    const contentRef = useRef<(HTMLElement & HTMLDivElement) | null>(null)

    const theme = useTheme()
    const isBrowser = useIsBrowser()

    const { width, height } = useWindowSize()

    const willChange = useWillChange()

    useKey(
        'Escape',
        () => {
            if (isOpen) onClose?.()
        },
        {},
        [isOpen]
    )

    const handleOutsideClick: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation()
        event.preventDefault()

        onClose?.()
    }

    useEffect(() => {
        if (!isBrowser) return

        document.body.style.overflow = isOpen ? 'hidden' : 'auto'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [isOpen, isBrowser])

    if (!isBrowser) return null

    const modalRoot = document.getElementById('modal-root')

    if (!modalRoot) return null

    return createPortal(
        <AnimatePresence mode="sync">
            {run(() => {
                if (!isOpen) return null

                return (
                    <motion.div
                        data-cy={dataCy}
                        data-testid={testId}
                        key="modal"
                        {...(styleType === 'flat'
                            ? {
                                  initial: { opacity: 0 },
                                  animate: { opacity: 1 },
                              }
                            : {
                                  initial: { backgroundColor: 'rgba(0,0,0,0)' },
                                  animate: { backgroundColor: 'rgba(0,0,0,0.5)' },
                              })}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                        onClick={handleOutsideClick}
                        style={{ willChange }}
                        css={css`
                            background: ${styleType === 'flat'
                                ? theme.colors.background.standard
                                : theme.colors.background.dark};
                            z-index: ${theme.zIndex.modal};
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: fixed;
                            top: 0;
                            left: 0;
                            width: 100vw;
                            height: 100vh;

                            ${overlayStyles}
                        `}
                        {...overlayMotion}
                    >
                        {showConfetti && <ReactConfetti width={width} height={height} />}

                        <motion.div
                            onClick={(e) => e.stopPropagation()}
                            ref={contentRef}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3, ease: 'easeOut' }}
                            css={css`
                                background: ${theme.colors.background.light};
                                border-radius: ${theme.borderRadius.modal || theme.borderRadius.default};
                                padding: ${theme.spacing(3)};

                                box-shadow: ${styleType === 'flat' ? theme.boxShadow.medium : 'none'};

                                ${modalStyles};
                            `}
                            {...modalMotion}
                        >
                            {onClose && (
                                <Button
                                    data-testid="close-modal"
                                    dataCy="close-modal"
                                    onClick={onClose}
                                    styleType="inline-unstyled"
                                    animations={{ whileHover: { scale: 1.25 } }}
                                    buttonStyles={css`
                                        display: flex;
                                        align-self: flex-end;
                                        margin-bottom: ${theme.spacing(2.5)};
                                        height: 24px;
                                        padding: 0;
                                        border-radius: 8px;

                                        &:hover {
                                            background-color: ${theme.colors.background.standard} !important;
                                        }
                                    `}
                                >
                                    <EnterIcon name={IconName.XMark} />
                                </Button>
                            )}

                            {children}
                        </motion.div>
                    </motion.div>
                )
            })}
        </AnimatePresence>,
        modalRoot
    )
}

export const ModalRoot = () => {
    const theme = useTheme()
    return (
        <div
            id="modal-root"
            css={css`
                position: relative;
                z-index: ${theme.zIndex.overlay};
            `}
        />
    )
}

export default DefaultModal
