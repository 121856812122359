import { OwnerType } from '@design-system/api/docspace/docspaceTypes'
import {
    BuildingMeasuresValues,
    MeasureNamePath,
    SavingsPotentialUnit,
    SurveyName,
    SurveyNamePath,
} from '@design-system/types/buildings/buildingsTypes'

import { PublicAuthProcesses } from './authentication'

export const SAVINGS_POTENTIAL_TAB_BAR_QUERY_PARAM_KEY = 'tab'
export const EMAIL_VERIFICATION_CONTENT_TYPE_QUERY_PARAM_KEY = 'contentType'
export const SUBSIDIZABLE_MEASURE_COMPONENT_QUERY_PARAM_KEY = 'subsidizableComponent'
export const RESET_PASSWORD_TOKEN_QUERY_PARAM_KEY = 'resetPasswordToken'

// SUB_PARTNER_ID is used in the URL to identify the sub-partner
export enum TrackingTags {
    GA_CLICK_ID = 'gclid',
    UTM_SOURCE = 'utm_source',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_CAMPAIGN_ID = 'utm_campaign_id',
    UTM_MEDIUM = 'utm_medium',
    UTM_CONTENT = 'utm_content',
    UTM_TERM = 'utm_term',
    ENTER_MEDIUM = 'enter_medium',
    ENTER_CAMPAIGN = 'enter_campaign',
    ENTER_ADGROUP = 'enter_adgroup',
    ENTER_CAMPAIGN_ID = 'enter_campaign_id',
    ENTER_SOURCE = 'enter_source',
    ENTER_KEYWORD = 'enter_keyword',
    ENTER_CONTENT = 'enter_content',
    ENTER_LANDING_URL = 'enter_landingurl',
    ENTER_LEAD_URL = 'enter_leadurl',
    ENTER_DEVICE = 'enter_device',
    PARTNER_CUSTOMER = 'partner_customer',
    SCOUT_ID = 'scout_id',
    CUSTOMER_REFERRAL = 'customer_referral',
    PARTNER_REFERRAL = 'partner_referral',
    INSTALLATION_PARTNER_REFERRAL = 'installation_partner_referral',
    VPARTNER_ID = 'vpartner_id',
    PARTNER_ID = 'partner_id',
    ROLLOUT_QUERY_PARAM = 'rollout',
    EXTERNAL_AGENT_ID = 'external_agent_id',
    EXTERNAL_CUSTOMER_ID = 'external_customer_id',
    SUB_PARTNER_ID = 'sub_partner_id',
    WT_MC = 'wt_mc',
}

export enum FormParams {
    FORM_UUID = 'formUuid',
    STEP_UUID = 'stepUuid',
    SURVEY_NAME = 'surveyName',
    BUILDING_UUID = 'buildingUuid',
}

export type FormQuery = {
    [FormParams.FORM_UUID]?: string
    [FormParams.STEP_UUID]?: string
    [FormParams.SURVEY_NAME]?: string
    [FormParams.BUILDING_UUID]?: string
}

export type TrackingQuery = {
    [value in TrackingTags]?: string
}

// TODO: Add other param keys to enum.
export enum QueryParamKey {
    DOCUMENT_SPACE_SOURCE = 'source',
}

export enum EmailVerificationContentType {
    INITIAL = 'initial',
    FAILED = 'failed',
}

export type FormsPath = {
    [FormParams.FORM_UUID]: string
    [FormParams.STEP_UUID]?: string
    [TrackingTags.GA_CLICK_ID]?: string
}

export type UnauthenticatedFormsArgs = FormsPath

export type AuthenticatedFormsArgs = FormsPath & {
    [FormParams.BUILDING_UUID]: string
}

export enum FormUuid {
    WELCOME_FORM = 'welcome-form',
    RESTRUCTURING_CONSULTATION = 'restructuring-consultation',
    RESTRUCTURING_MEASURE = 'restructuring-measure',
    SAVINGS_POTENTIAL = 'savings-potential',
}

export enum OfferMeasure {
    PV = 'pv',
    HEATPUMP = 'heat-pump',
    WINDOW = 'window',
    EBD = 'ebd',
}

export type BuildingSurveyArgs = {
    [FormParams.SURVEY_NAME]: SurveyName
    [FormParams.FORM_UUID]?: string
    [FormParams.STEP_UUID]?: string
    [FormParams.BUILDING_UUID]: string
}

export type BuildingSummaryArgs = {
    [FormParams.SURVEY_NAME]: SurveyName
    [FormParams.BUILDING_UUID]: string
}

type DocumentSpaceArgs = {
    buildingUuid: string
    folderUuid?: string
    source?: OwnerType
}

type DashboardArgs = {
    buildingUuid: string
    activeTab?: SavingsPotentialUnit
}

type SavingsPotentialArgs = {
    buildingUuid: string
    activeTab?: SavingsPotentialUnit
}

type RenovationMeasuresArgs = {
    buildingPart: BuildingMeasuresValues
    activeTab?: SavingsPotentialUnit
    buildingUuid: string
}

export const Page = {
    UNKNOWN_BUILDING_DASHBOARD: '/',

    DASHBOARD: ({ buildingUuid, activeTab = SavingsPotentialUnit.EURO }: DashboardArgs) => {
        if (!buildingUuid) return Page.UNKNOWN_BUILDING_DASHBOARD
        return `/dashboard/${buildingUuid}?${SAVINGS_POTENTIAL_TAB_BAR_QUERY_PARAM_KEY}=${activeTab}`
    },

    PROFILE: '/profile',
    COMPLETE_PROFILE: '/profile/complete',
    CONSULTATION: '/consultation',

    LOGIN: '/auth/login',
    AUTH_PROCESS: (authProcess: PublicAuthProcesses) => `/auth/${authProcess}`,
    OFFER_PAGE: (buildingUuid: string) => `/offer-page/${buildingUuid}`,
    PV_OFFER_PAGE: (buildingUuid: string) => `/offers/pv/${buildingUuid}`,
    OFFER_PAGE_MEASURE: (measure: OfferMeasure, buildingUuid: string) => `/offers/${measure}/${buildingUuid}`,
    EMAIL_VERIFICATION_PROMPT: (queryParam: EmailVerificationContentType) =>
        `/auth/email-verification?${EMAIL_VERIFICATION_CONTENT_TYPE_QUERY_PARAM_KEY}=${queryParam}`,
    MAGIC_LINK_VERIFICATION_PROMPT: 'auth/magic-link-verification',

    WELCOME: '/welcome',

    ISFP_BOOKED: '/savings-potential/isfp-booked',

    EXPLORE: ({ buildingUuid, activeTab = SavingsPotentialUnit.EURO }: DashboardArgs) =>
        `/explore/${buildingUuid}?${SAVINGS_POTENTIAL_TAB_BAR_QUERY_PARAM_KEY}=${activeTab}`,

    HEAT_PUMP: `/check/heat-pump`,
    HEAT_PUMP_CHECK: (buildingUuid: string) => `/check/heat-pump/${buildingUuid}`,
    OFFER_OVERVIEW: (buildingUuid: string) => `/offers/overview/${buildingUuid}`,

    WELCOME_STEP: (stepUuid: string) => `/welcome/${stepUuid}`,

    UN_AUTHENTICATED_FORMS: (args: UnauthenticatedFormsArgs) => `/forms/${args.formUuid}/${args.stepUuid}`,

    AUTHENTICATED_FORMS: (args: AuthenticatedFormsArgs) => {
        const basePath = `/forms/building/${args.buildingUuid}/${args.formUuid}/${args.stepUuid}`

        return args.gclid ? `${basePath}?${TrackingTags.GA_CLICK_ID}=${args.gclid}` : basePath
    },

    DOCUMENT_SPACE: (args: DocumentSpaceArgs) => {
        const basePath = `/document-space/${args.buildingUuid}`
        const queryParams = `?${QueryParamKey.DOCUMENT_SPACE_SOURCE}=${args.source || OwnerType.USER}`

        if (!args.folderUuid) return `${basePath}/overview${queryParams}`

        return `${basePath}/${args.folderUuid}${queryParams}`
    },

    BASE_SAVINGS_POTENTIAL: `/savings-potential`,
    SAVINGS_POTENTIAL: ({ buildingUuid, activeTab = SavingsPotentialUnit.EURO }: SavingsPotentialArgs) =>
        `/savings-potential/${buildingUuid}?${SAVINGS_POTENTIAL_TAB_BAR_QUERY_PARAM_KEY}=${activeTab}`,

    RENOVATION_MEASURES: ({
        buildingUuid,
        buildingPart,
        activeTab = SavingsPotentialUnit.EURO,
    }: RenovationMeasuresArgs) =>
        buildingPart === BuildingMeasuresValues.HEAT_PUMP
            ? `/check/heat-pump/${buildingUuid}`
            : `/renovation-measures/${buildingUuid}?${SUBSIDIZABLE_MEASURE_COMPONENT_QUERY_PARAM_KEY}=${buildingPart}&${SAVINGS_POTENTIAL_TAB_BAR_QUERY_PARAM_KEY}=${activeTab}`,

    RETROFIT_DETAILS: ({ buildingUuid, buildingPart, activeTab = SavingsPotentialUnit.EURO }: RenovationMeasuresArgs) =>
        `/renovation-measures/${buildingUuid}?${SUBSIDIZABLE_MEASURE_COMPONENT_QUERY_PARAM_KEY}=${buildingPart}&${SAVINGS_POTENTIAL_TAB_BAR_QUERY_PARAM_KEY}=${activeTab}`,

    BUILDING_SURVEY: (args: BuildingSurveyArgs) => {
        const rootPath = `/building/${args.buildingUuid}/details`
        const overviewPath =
            args?.surveyName && args.surveyName in SurveyNamePath
                ? `${rootPath}/${SurveyNamePath[args.surveyName]}`
                : rootPath
        return args.formUuid ? `${overviewPath}/${args.formUuid}/${args.stepUuid}` : overviewPath
    },

    BUILDING_SUMMARY: (args: BuildingSummaryArgs) => {
        const surveyName =
            args.surveyName in MeasureNamePath
                ? MeasureNamePath[args.surveyName as keyof typeof MeasureNamePath]
                : args.surveyName in SurveyNamePath
                  ? SurveyNamePath[args.surveyName]
                  : undefined
        const rootPath = `/building/${args.buildingUuid}/summary`
        return surveyName ? `${rootPath}/${surveyName}` : `${rootPath}`
    },
    PROPERTY_VALUE_SUMMARY: ({ buildingUuid }: { buildingUuid: string }) => `/property-value/${buildingUuid}/summary`,
    PROPERTY_SPACE_FORM: ({ buildingUuid }: { buildingUuid: string }) =>
        `/property-value/${buildingUuid}/land-space-form/land-space`,
    CONFIRMATORY_DOCUMENT: ({ buildingUuid }: { buildingUuid: string }) =>
        `/building/${buildingUuid}/confirmatory-document`,
    EB_SLIDES: ({ buildingUuid }: { buildingUuid: string }) => `/building/${buildingUuid}/eb-slide`,
}

export const External = {
    DATA_PRIVACY: 'https://www.baupal.de/datenschutz',
    IMPRINT: 'https://www.baupal.de/impressum',
    LANDING_PAGE: 'https://www.enter.de',

    SUBSIDIES_APPOINTMENT: 'https://outlook.office365.com/book/TelefonatFrderservice@enter.de/',
    HEAT_PUMP_APPOINTMENT:
        'https://outlook.office365.com/owa/calendar/WrmepumpenCheck@enter.de/bookings/s/4zZODt-78EesDbmnzGJ8UQ2',
}

export const Calendly = {
    INITIAL_CONTACT:
        'https://calendly.com/team-enter-beratung/kostenloses-erstgesprach-energieberatung-02?hide_gdpr_banner=1&embed_domain=app.baupal.de&embed_type=Inline',
    RENOVATION_SCHEDULE: 'https://calendly.com/team-enter-beratung/kostenloses-erstgesprach-energieberatung-02',
    FIRST_CONTACT: 'https://calendly.com/team-enter-beratung/kostenloses-erstgesprach-energieberatung-02',
    COMPARE_OFFER: 'https://calendly.com/team-enter-angebotsservice/kostenloses-erstgesprach-angebotsvergleich',
    SUBSIDY_SERVICE: 'https://calendly.com/team-enter-beratung/kostenloses-erstgesprach-forderservice',
    CONSTRUCTION_WORKER_OFFER:
        'https://calendly.com/team-enter-angebotsservice/kostenloses-erstgesprach-handwerker-angebote',
    FINANCING: 'https://calendly.com/team-enter-finance/kostenloses-erstgesprach-finanzierungsanfrage',
    PV_CONSULTATION: 'https://calendly.com/photovoltaik-erstgespraech/photovoltaik-erstgesprach',
}
