import { useMemo, ReactNode } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { EnterTheme, useTheme } from '@design-system/styles/theme'
import { motion, MotionProps } from 'framer-motion'
import { GTMDataLayerProps, GTMEvent, GTMSessionDataStorageKey } from '@design-system/types/GTMTypes'
import { LoadingSpinner } from '@design-system/components/loading/loadingSpinner'
import { sendGTMEvent } from '@design-system/utils/googleTagManager'

type IncompatibleProps = 'onDrag' | 'onDragEnd' | 'onDragStart' | 'onAnimationStart' | 'ref'

export type StylesType = SerializedStyles | SerializedStyles[]
export interface ButtonProps extends GTMDataLayerProps {
    isDisabled?: boolean
    children?: ReactNode
    icon?: ReactNode
    type?: 'button' | 'submit' | 'reset'
    onClick?: () => void
    isLoading?: boolean
    iconContainerStyles?: SerializedStyles
    styleType:
        | 'primary'
        | 'primary-outlined'
        | 'secondary'
        | 'secondary-outlined'
        | 'inline-unstyled'
        | 'step-change'
        | 'rounded-secondary'
        | 'rounded-outlined-secondary'
    buttonStyles?: StylesType
    childrenContainerStyles?: StylesType
    dataCy?: string
    animations?: Omit<MotionProps, 'style' | IncompatibleProps>
    size?: 'small' | 'medium'
}

export const Button = ({
    children,
    gtmValue,
    gtmData = {},
    styleType,
    icon,
    iconContainerStyles,
    type,
    onClick,
    isLoading = false,
    isDisabled,
    dataCy,
    buttonStyles: propButtonStyles,
    childrenContainerStyles: propChildrenContainerStyles,
    animations: propsAnimations,
    size = 'medium',
}: ButtonProps) => {
    const theme = useTheme()

    const gtmSessionData = useMemo(() => {
        if (typeof window !== 'undefined') {
            const gtmSessionDataString = window?.sessionStorage?.getItem(GTMSessionDataStorageKey)
            if (gtmSessionDataString) return JSON.parse(gtmSessionDataString)
        }
    }, [])

    const {
        buttonStyles,
        childrenContainerStyles,
        contentColor,
        scale,
        iconContainerStyles: baseIconContainerStyles,
    } = getStylesFromStyleType(styleType, theme, isLoading, size)

    const animations = useMemo(
        () => getScaleAnimation(scale, propsAnimations, isDisabled),
        [propsAnimations, isDisabled, scale]
    )

    const onButtonClick = () => {
        if (!onClick) return

        if (gtmValue) {
            const GTMData = {
                ...(typeof gtmData === 'object' ? gtmData : {}),
                ...(gtmSessionData || {}),
            }
            sendGTMEvent(GTMEvent.UI_BUTTON_INTERACTION, gtmValue, GTMData)
        }

        onClick()
    }

    return (
        <motion.button
            role="button"
            disabled={isDisabled}
            type={type || 'button'}
            data-cy={dataCy}
            data-testid={dataCy}
            css={css`
                font-family: ${theme.fontFamily.bodyDefault};
                transform-origin: center;
                backface-visibility: hidden;
                transform: translateZ(0);
                ${buttonStyles}
                ${propButtonStyles}
            `}
            onClick={onButtonClick}
            {...animations}
        >
            <div
                css={css`
                    transform: translateZ(0);
                    ${childrenContainerStyles}
                    ${propChildrenContainerStyles}
                `}
            >
                {icon && !isLoading && (
                    <div
                        css={css`
                            ${baseIconContainerStyles}

                            ${iconContainerStyles}
                        `}
                    >
                        {icon}
                    </div>
                )}

                <LoadingSpinner
                    height="26px"
                    width="26px"
                    color={contentColor}
                    styles={css`
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        display: ${isLoading ? 'block' : 'none'};
                    `}
                />

                {isLoading ? (
                    <div
                        css={css`
                            opacity: 0;
                        `}
                    >
                        {children}
                    </div>
                ) : (
                    children
                )}
            </div>
        </motion.button>
    )
}

export const getScaleAnimation = (scale: number, propsAnimations?: ButtonProps['animations'], isDisabled?: boolean) => {
    const { whileHover, ...otherButtonAnimations } = propsAnimations || {}

    const mergeableInjectedHoverAnimation =
        typeof whileHover === 'object' && !Array.isArray(whileHover) ? whileHover : {}

    return {
        whileHover: isDisabled
            ? undefined
            : {
                  scale,
                  ...mergeableInjectedHoverAnimation,
              },
        ...otherButtonAnimations,
    }
}

export const DEFAULT_BUTTON_SCALE = 1.03
export const UNSTYLED_BUTTON_SCALE = 1.01

const getStylesFromStyleType = (
    styleType: ButtonProps['styleType'],
    theme: EnterTheme,
    isLoading: boolean,
    size: ButtonProps['size']
): {
    buttonStyles: SerializedStyles
    childrenContainerStyles: SerializedStyles
    iconContainerStyles: SerializedStyles
    contentColor: string
    scale: number
} => {
    const sharedButtonStylesPadding = size === 'small' ? '10px 32px' : theme.spacing(2)
    const sharedButtonStyles = css`
        position: relative;
        cursor: pointer;
        padding: ${sharedButtonStylesPadding};
        transition-property: filter, background, color, background-color;
        transition-duration: 0.2s;

        border-radius: ${theme.borderRadius.button || theme.borderRadius.default};
        font-size: ${theme.fontSize.button || theme.fontSize.bodyDefault};
        font-weight: ${theme.fontWeight.button || theme.fontWeight.bodyDefault};
        line-height: ${theme.lineHeight.button || theme.lineHeight.bodyDefault};

        -webkit-tap-highlight-color: transparent;

        &:focus-visible {
            outline: none;
        }

        &:disabled {
            cursor: not-allowed;
        }
    `

    const sharedChildrenContainerStyles = css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: ${theme.fontSize.button};
        line-height: 18px;
    `

    const sharedIconContainerStyles = css`
        display: flex;
        align-items: center;
        justify-content: center;
    `

    switch (styleType) {
        case 'primary':
            return {
                buttonStyles: css`
                    ${sharedButtonStyles}
                    background-color: ${theme.colors.interactive.primary.default.background};
                    color: ${theme.colors.interactive.primary.default.color};
                    border: 1px solid ${theme.colors.interactive.primary.default.border};

                    &:disabled {
                        filter: none;
                        background-color: ${theme.colors.interactive.primary.disabled.background};
                        color: ${theme.colors.interactive.primary.disabled.color};
                        border-color: ${theme.colors.interactive.primary.disabled.border};
                    }
                `,
                childrenContainerStyles: css`
                    ${sharedChildrenContainerStyles}
                `,
                contentColor: theme.colors.interactive.primary.default.color,
                scale: DEFAULT_BUTTON_SCALE,
                iconContainerStyles: css`
                    ${sharedIconContainerStyles}
                `,
            }

        case 'primary-outlined':
            return {
                buttonStyles: css`
                    ${sharedButtonStyles}
                    background-color: transparent;
                    color: ${theme.colors.primary.dark};
                    border: 1px solid ${theme.colors.primary.dark};
                `,
                childrenContainerStyles: css`
                    ${sharedChildrenContainerStyles}
                `,
                contentColor: theme.colors.primary.dark,
                scale: DEFAULT_BUTTON_SCALE,
                iconContainerStyles: css`
                    ${sharedIconContainerStyles}
                `,
            }

        case 'secondary': {
            const disabledStyles = isLoading
                ? css`
                      filter: none !important;
                  `
                : css`
                      filter: none;
                      background-color: ${theme.colors.interactive.secondary.disabled.background};
                      color: ${theme.colors.interactive.secondary.disabled.color};
                      border-color: ${theme.colors.interactive.secondary.disabled.border};
                  `

            return {
                buttonStyles: css`
                    ${sharedButtonStyles}
                    background-color: ${theme.colors.interactive.secondary.default.background};
                    color: ${theme.colors.interactive.secondary.default.color};
                    border: 1px solid ${theme.colors.interactive.secondary.default.border};

                    &:disabled {
                        ${disabledStyles}
                    }
                `,
                childrenContainerStyles: css`
                    ${sharedChildrenContainerStyles}
                `,
                contentColor: theme.colors.interactive.secondary.default.color,
                scale: DEFAULT_BUTTON_SCALE,
                iconContainerStyles: css`
                    ${sharedIconContainerStyles}
                `,
            }
        }

        case 'secondary-outlined':
            return {
                buttonStyles: css`
                    ${sharedButtonStyles}
                    background-color: transparent;
                    border: 1px solid ${theme.colors.secondary.standard};
                    color: ${theme.colors.secondary.standard};
                `,

                childrenContainerStyles: css`
                    ${sharedChildrenContainerStyles}
                `,
                contentColor: theme.colors.secondary.standard,
                scale: DEFAULT_BUTTON_SCALE,
                iconContainerStyles: css`
                    ${sharedIconContainerStyles}
                `,
            }
        case 'step-change':
            return {
                buttonStyles: css`
                    ${sharedButtonStyles}
                    background-color: ${theme.colors.primary.standard};
                    color: ${theme.colors.primary.dark};
                    padding: 12px 15px;
                    border: 1px solid ${theme.colors.primary.standard};
                    border-radius: ${theme.borderRadius.default};

                    &:disabled {
                        background-color: ${theme.colors.primary.light};
                        color: ${theme.colors.primary.dark};
                    }
                `,
                childrenContainerStyles: css`
                    ${sharedChildrenContainerStyles}
                `,
                contentColor: theme.colors.primary.dark,
                scale: DEFAULT_BUTTON_SCALE,
                iconContainerStyles: css`
                    ${sharedIconContainerStyles}
                `,
            }

        case 'rounded-secondary':
            return {
                buttonStyles: css`
                    ${sharedButtonStyles}

                    padding: ${theme.spacing(1)};
                    border: 1px solid ${theme.colors.primary.dark};
                    background-color: ${theme.colors.primary.dark};
                    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.18);
                    color: ${theme.colors.primary.light};
                `,
                childrenContainerStyles: css`
                    ${sharedChildrenContainerStyles}

                    font-size: 12px;
                `,
                contentColor: theme.colors.primary.dark,
                scale: DEFAULT_BUTTON_SCALE,
                iconContainerStyles: css`
                    ${sharedIconContainerStyles}
                `,
            }

        case 'rounded-outlined-secondary':
            return {
                buttonStyles: css`
                    ${sharedButtonStyles}

                    padding: ${theme.spacing(1)};
                    border: 1px solid ${theme.colors.primary.dark};
                    background-color: transparent;
                    color: ${theme.colors.primary.dark};
                `,
                childrenContainerStyles: css`
                    ${sharedChildrenContainerStyles}

                    font-size: 12px;
                `,
                contentColor: theme.colors.primary.dark,
                scale: DEFAULT_BUTTON_SCALE,
                iconContainerStyles: css`
                    ${sharedIconContainerStyles}
                `,
            }

        case 'inline-unstyled':
            return {
                buttonStyles: css`
                    ${sharedButtonStyles}
                    background-color: transparent;
                    border: none;
                    padding: 0 4px;
                `,
                childrenContainerStyles: css`
                    ${sharedChildrenContainerStyles}
                `,
                contentColor: theme.colors.primary.dark,
                scale: UNSTYLED_BUTTON_SCALE,
                iconContainerStyles: css`
                    ${sharedIconContainerStyles}
                `,
            }
    }
}
